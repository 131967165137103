// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import SupportTicketContainer from './support/SupportTicketContainer';

// Views
import NotFound from 'views/NotFound';

// Styles
import 'assets/styles/_layout.scss';

class SupportContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/support" to="/support/support-tickets" />

        <Route exact path="/support/support-tickets" component={ SupportTicketContainer } />
        <Route path="/support/support-tickets/:record_id" component={ SupportTicketContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

export default SupportContainer;
