// Libs
import React from 'react';
import ReactQuill from 'react-quill';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { Col, InputNumber, Modal, Select, Table, Tooltip, Row } from 'antd';
import Dropdown from 'components/dropdown';
import TabView from 'components/tab-view';
import Badge, { BadgeType } from 'components/badge';
import Timeline from 'components/timeline';
import FieldWrapper from 'components/form/field/field-wrapper';
import Divider from 'components/form/field/divider';
import DayTimePicker from 'components/day-time-picker';

// Icons
import { ClockCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Utils
import { isBlank, timeConvert } from 'utils/utils';
import { orderListByKey } from 'utils/formSetup';
import { getFormatedDate } from 'services/settings';

// Interfaces
import { KpiMetric, KpiMetricRecordType, KpiMetricLevel, KpiMetricType, KpiPrioritySet, KpiPriority, MetricType, Modified, MetricLevelRow, MetricStatus, MetricOnCreate, MetricOnEdit, MetricOnPublish, MetricOnCreateVersion, MetricReportingPeriod } from './KpiLibrary.interfaces';
import { TimelineItem } from 'components/timeline/Timeline.interface';

// Styles
import './KpiLibrary.scss';

const { confirm } = Modal;

const getBlankMetric = (): any => {
  const uniqueKey: string = uuidv4();
  return {
    id: uniqueKey,
    title: '',
    status: MetricStatus.Pending,
    kpi_metric_type_id: undefined,
    kpi_metric_type: undefined,
    kpi_priority_set_id: undefined,
    kpi_metric_levels: [],
    reporting_period: _.upperCase(MetricReportingPeriod.MONTHLY),
    isNewMetric: true, // The property is needed to recognize the new metric
  };
};

const getBlankMetricLevel = (metricId: KpiMetric['id'], kpi_priority: KpiPriority): any => {
  const uniqueKey: string = uuidv4();
  return {
    id: uniqueKey,
    description: null,
    target: null,
    target_description: null,
    measure: null,
    config: null,
    kpi_metric_id: metricId,
    kpi_priority_id: kpi_priority.id,
    kpi_priority: kpi_priority,
    isNewMetricLevel: true, // The property is needed to recognize the new metric level
  };
};

interface Props {
  kpiMetric: KpiMetric | null;
  kpiMetricRecordTypes: KpiMetricRecordType[];
  kpiMetricTypes: KpiMetricType[];
  kpiPrioritySets: KpiPrioritySet[];
  isModifying?: boolean;
  isLoading?: boolean;
  isPublishing?: boolean;
  isVersioning?: boolean;
  canEdit?: boolean;
  canApprove?: boolean;
  onCreate?: MetricOnCreate;
  onEdit?: MetricOnEdit;
  onPublish?: MetricOnPublish,
  onVersion?: MetricOnCreateVersion,
  onChangeStatus?: (metricId: KpiMetric['id'], status: MetricStatus) => Promise<void>;
  clientId: number | undefined;
};

interface State {
  originalKpiMetric: KpiMetric | null;
  modifiedKpiMetric: KpiMetric;
};

class KpiMetricForm extends React.Component<Props, State> {

  tabViewRef: any = React.createRef();

  state: State = {
    originalKpiMetric: this.props.kpiMetric ? _.cloneDeep(this.props.kpiMetric) : null,
    modifiedKpiMetric: this.props.kpiMetric ? _.cloneDeep(this.props.kpiMetric) : getBlankMetric(),
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.kpiMetric !== this.props.kpiMetric) {
      this.setState({
        originalKpiMetric: this.props.kpiMetric ? _.cloneDeep(this.props.kpiMetric) : null,
        modifiedKpiMetric: this.props.kpiMetric ? _.cloneDeep(this.props.kpiMetric) : getBlankMetric(),
      });
    }
  };

  getModified = (metric: KpiMetric, originalMetric: KpiMetric | null): Modified => {
    const modified: Modified = {};

    // check metric
    const metricKeys: Array<keyof Omit<Modified, 'kpi_metric_levels'>> = ['kpi_metric_type_id', 'kpi_priority_set_id', 'reporting_period'];
    metricKeys.forEach((key) => !_.isEqual(metric[key], originalMetric?.[key]) && _.set(modified, key, true));

    // check metric levels
    if (!_.isEqual(metric.kpi_metric_levels, originalMetric?.kpi_metric_levels)) {
      metric.kpi_metric_levels.forEach((metricLevel: KpiMetricLevel) => {
        const levelModified: string[] = [];
        const levelKeys: Array<keyof KpiMetricLevel> = ['description', 'target', 'target_description', 'measure'];

        const originalLevel: KpiMetricLevel | undefined = originalMetric?.kpi_metric_levels?.find(level => level.id === metricLevel.id);
        if (!originalLevel) {
          levelModified.push(...levelKeys);
        } else {
          levelKeys.forEach((key: keyof KpiMetricLevel) => !_.isEqual(metricLevel[key], originalLevel[key]) && levelModified.push(key));
        }

        if (!_.isEmpty(levelModified)) {
          modified.kpi_metric_levels = Object.assign({}, modified.kpi_metric_levels, { [metricLevel.id]: levelModified });
        }
      });
    }

    return modified;
  };

  getErrors = (metric: KpiMetric): Modified => {
    const errors: Modified = {};

    // check metric
    const metricKeys: Array<keyof Omit<Modified, 'kpi_metric_levels'>> = ['kpi_metric_type_id', 'kpi_priority_set_id', 'reporting_period'];
    metricKeys.forEach((key) => isBlank(metric[key]) && _.set(errors, key, true));

    // check metric levels
    if (!_.isEmpty(metric.kpi_metric_levels)) {
      metric.kpi_metric_levels.forEach((metricLevel: KpiMetricLevel) => {
        const levelErrors: string[] = [];

        if (!metricLevel.description?.replace(/<[^>]*>?/gm, '')) {
          levelErrors.push('description');
        }

        if (!metricLevel.target_description?.replace(/<[^>]*>?/gm, '')) {
          levelErrors.push('target_description');
        }

        if (isBlank(metricLevel.target) || metricLevel.target <= 0) {
          levelErrors.push('target');
        }

        // check the measure field, the structure of the field depends on the metric type
        if (metric.kpi_metric_type?.type === MetricType.HelpDesk) {
          const minutes: number = metricLevel.measure?.minutes || 0;
          if (minutes <= 0) {
            levelErrors.push('measure');
          }
        } else if (metric.kpi_metric_type?.type === MetricType.Budget) {
          const { from = 0, to = 0 } = metricLevel.measure || {};
          if (from >= to) {
            levelErrors.push('measure');
          }
        }

        if (!_.isEmpty(levelErrors)) {
          errors.kpi_metric_levels = Object.assign({}, errors.kpi_metric_levels, { [metricLevel.id]: levelErrors });
        }
      });
    }

    return errors;
  };

  isModifiedMetric = (modified: Modified, fieldKey: keyof Omit<Modified, 'kpi_metric_levels'>): boolean => {
    return !!modified[fieldKey];
  };

  isModifiedMetricLevel = (modified: Modified, levelId: KpiMetricLevel['id'], fieldKey: keyof MetricLevelRow): boolean => {
    return !!modified?.kpi_metric_levels?.[levelId]?.includes(fieldKey);
  };

  hasMetricError = (errors: Modified, fieldKey: keyof Omit<Modified, 'kpi_metric_levels'>): boolean => {
    return !!errors[fieldKey];
  };

  hasMetricLevelError = (errors: Modified, levelId: KpiMetricLevel['id'], fieldKey: keyof MetricLevelRow): boolean => {
    return !!errors?.kpi_metric_levels?.[levelId]?.includes(fieldKey);
  };

  getRecordCompatiblePrioritySet = (kpiMetric: KpiMetric): KpiPrioritySet[] => {
    const { kpiMetricTypes, kpiPrioritySets } = this.props;

    let compatiblePrioritySets: KpiPrioritySet[] = [];
    if (kpiMetric.kpi_metric_record_type) {
      kpiPrioritySets.map((_kpiPrioritySet: KpiPrioritySet) => {
        if (_.has(_kpiPrioritySet, 'config.entities') && _kpiPrioritySet.config && !_.isEmpty(_kpiPrioritySet.config.entities)) {
          if (_kpiPrioritySet.config.entities.find((entity: any) => entity.type === kpiMetric.kpi_metric_record_type)) {
            compatiblePrioritySets.push(_kpiPrioritySet);
          }
        }
      });
    }

    let singleKpiPrioritySet = undefined;
    const metricType: KpiMetricType | undefined = kpiMetricTypes.find(_kpiMetricType => _kpiMetricType.id === kpiMetric?.kpi_metric_type_id);
    if (metricType) {
      if (_.has(metricType, 'config.force_priority_set')) {
        singleKpiPrioritySet = kpiPrioritySets.find(kpiPrioritySet => kpiPrioritySet.reference === metricType.config.force_priority_set);
        if (singleKpiPrioritySet) {
          compatiblePrioritySets = [singleKpiPrioritySet];
        }
      }
    }

    return compatiblePrioritySets;
  };

  onChangeStatus = (metricId: KpiMetric['id'], status: MetricStatus) => {
    this.props.onChangeStatus?.(metricId, status);
  };

  updatePrioritySet = (metricId: KpiMetric['id'], prioritySetId: KpiPrioritySet['id']) => {
    const { kpiPrioritySets } = this.props;
    const { modifiedKpiMetric } = this.state;

    const kpiPrioritySet: KpiPrioritySet | undefined = kpiPrioritySets.find((prioritySet: KpiPrioritySet) => prioritySet.id === prioritySetId);
    if (!kpiPrioritySet) return;

    const updatedState = {
      kpi_priority_set_id: prioritySetId,
      kpi_metric_levels: kpiPrioritySet.kpi_priorities.map((priority: KpiPriority) => getBlankMetricLevel(metricId, priority))
    };

    this.setState({ modifiedKpiMetric: { ...modifiedKpiMetric, ...updatedState } });
  };

  onChangePrioritySet = (metricId: KpiMetric['id'], prioritySetId: KpiPrioritySet['id'], showConfirmation?: boolean): void => {
    if (showConfirmation) {
      confirm({
        title: 'Changing the priority set will clear all level data below',
        content: 'Are you sure you want to proceed?',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          this.updatePrioritySet(metricId, prioritySetId);
        },
      });
    } else {
      this.updatePrioritySet(metricId, prioritySetId);
    }
  };

  modifyMetric = (metricId: KpiMetric['id'], key: keyof KpiMetric, value: any): void => {
    const { kpiMetricRecordTypes, kpiMetricTypes, kpiPrioritySets } = this.props;
    const { modifiedKpiMetric } = this.state;

    const updatedProps = { [key]: value };

    if (key === 'kpi_metric_record_type') {
      const kpiMetricRecordType: KpiMetricRecordType | undefined = kpiMetricRecordTypes.find((metricRecordType: KpiMetricRecordType) => metricRecordType.type === value);
      if(!!kpiMetricRecordType) {
        _.set(updatedProps, 'kpi_metric_record_type', kpiMetricRecordType.type);
        _.set(updatedProps, 'kpi_metric_type_id', undefined);
        _.set(updatedProps, 'kpi_priority_set_id', undefined);
        _.set(updatedProps, 'kpi_priority_set', undefined);
      }
    }

    if (key === 'kpi_metric_type_id') {
      const kpiMetricType: KpiMetricType | undefined = kpiMetricTypes.find((metricType: KpiMetricType) => metricType.id === value);
      if(!!kpiMetricType) {
        _.set(updatedProps, 'kpi_metric_type', kpiMetricType);
        _.set(updatedProps, 'kpi_priority_set_id', undefined);
        _.set(updatedProps, 'kpi_priority_set', undefined);

        let singleKpiPrioritySet = undefined;
        const metricType: KpiMetricType | undefined = kpiMetricTypes.find(_kpiMetricType => _kpiMetricType.id === kpiMetricType.id);
        if (metricType) {
          if (_.has(metricType, 'config.force_priority_set')) {
            singleKpiPrioritySet = kpiPrioritySets.find(kpiPrioritySet => kpiPrioritySet.reference === metricType.config.force_priority_set);
            if (singleKpiPrioritySet) {
              _.set(updatedProps, 'kpi_priority_set_id', singleKpiPrioritySet.id);
              _.set(updatedProps, 'kpi_priority_set', singleKpiPrioritySet);
              _.set(updatedProps, 'kpi_metric_levels', singleKpiPrioritySet.kpi_priorities.map((priority: KpiPriority) => getBlankMetricLevel(metricId, priority)));
            }
          }
        }
      }
    }

    const compatiblePrioritySet: KpiPrioritySet[] = this.getRecordCompatiblePrioritySet({ ...modifiedKpiMetric, ...updatedProps });
    if (compatiblePrioritySet.length === 1) {
      _.set(updatedProps, 'kpi_priority_set_id', compatiblePrioritySet[0].id);
      _.set(updatedProps, 'kpi_priority_set', compatiblePrioritySet[0]);
    }

    this.setState({ modifiedKpiMetric: { ...modifiedKpiMetric, ...updatedProps } });
  };

  modifyMetricLevel = (metric: KpiMetric, metricLevelId: KpiMetricLevel['id'], key: string, value: any): void => {
    const updatedMetricLevels: KpiMetricLevel[] = metric.kpi_metric_levels.map((metricLevel: KpiMetricLevel) => {
      return metricLevel.id === metricLevelId ? { ...metricLevel, [key]: value } : metricLevel;
    });
    this.modifyMetric(metric.id, 'kpi_metric_levels', updatedMetricLevels);
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { !!tooltip && (
          <Tooltip className="mL-5 pT-1" placement="top" title={ tooltip }>
            <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
          </Tooltip>
        ) }
      </div>
    );
  };

  renderMeasureColumn = (metric: KpiMetric, modified: Modified, errors: Modified) => {
    if (MetricType.Budget === metric.kpi_metric_type?.type) {
      return {
        dataIndex: 'measure',
        key: 'measure',
        title: this.renderColumnTitle(['Service Level', 'Measure'], '', true),
        render: (measure: MetricLevelRow['measure'], row: MetricLevelRow) => {
          const hasError: boolean = this.hasMetricLevelError(errors, row.id, 'measure');
          const isModified: boolean = this.isModifiedMetricLevel(modified, row.id, 'measure');

          return (
            <div className="d-f jc-sb">
              <div className="d-f" style={{ width: '48%' }}>
                <InputNumber
                  value={ measure?.from || 0 }
                  className={ classNames('Field', {
                    'Field--has-error border-danger': hasError,
                    'Field--has-warning border-warning': isModified && !hasError,
                  }) }
                  formatter={ value => `${value}%` }
                  parser={ value => +value!.replace('%', '') }
                  onChange={ (value: number) => {
                    const newValue: number = (_.isNumber(value) && !!value) ? value : 0;
                    this.modifyMetricLevel(metric, row.id, 'measure', { from: newValue, to: measure?.to || 0 });
                  } }
                />
                { !!hasError &&
                  <Tooltip
                    overlayClassName="text-white"
                    placement="topRight"
                    title={ `the "from" value must be less than the "to" value` }
                  >
                    <WarningIcon className="text-danger mL-5 mT-5" height={ 20 } width={ 20 } />
                  </Tooltip>
                }
              </div>
              <InputNumber
                value={ measure?.to || 0 }
                className={ classNames('Field', {
                  'Field--has-error border-danger': hasError,
                  'Field--has-warning border-warning': isModified && !hasError,
                }) }
                style={{ width: '48%' }}
                formatter={ value => `${value}%` }
                parser={ value => +value!.replace('%', '') }
                onChange={ (value: number) => {
                  const newValue: number = (_.isNumber(value) && !!value) ? value : 0;
                  this.modifyMetricLevel(metric, row.id, 'measure', { from: measure?.from || 0, to: newValue });
                } }
              />
            </div>
          );
        },
        width: 250,
        ellipsis: true,
      };
    }

    if (MetricType.HelpDesk === metric.kpi_metric_type?.type) {
      return {
        dataIndex: 'measure',
        key: 'measure',
        title: this.renderColumnTitle(['Service Level', 'Measure'], '', true),
        render: (measure: MetricLevelRow['measure'], row: MetricLevelRow) => {
          const hasError: boolean = this.hasMetricLevelError(errors, row.id, 'measure');
          const isModified: boolean = this.isModifiedMetricLevel(modified, row.id, 'measure');

          // all the time in minutes
          const time: number = (!!measure?.minutes && _.isNumber(measure?.minutes)) ? measure?.minutes : 0;

          // converted minutes to hours and minutes
          const { days, hours, minutes } = timeConvert(time);

          return (
            <div className="d-f jc-sb">
              <DayTimePicker
                allowBackdrop
                showClockIcon
                defaultDays={ days }
                defaultHours={ hours }
                defaultMinutes={ minutes }
                hasError={ hasError }
                isModified={ isModified }
                onTimeChange={ (days: number | undefined, hours: number | undefined, minutes: number | undefined, callback: () => void) => {
                  const convertedDays: number = !!days ? days * 1440 : 0;
                  const convertedHours: number = !!hours ? hours * 60 : 0;
                  const convertedMinutes: number = !!minutes ? minutes : 0;
                  const newValue: number = convertedDays + convertedHours + convertedMinutes;
                  this.modifyMetricLevel(metric, row.id, 'measure', { minutes: newValue });
                  callback();
                } }
              />
            </div>
          );
        },
        width: 250,
        ellipsis: true,
      };
    }

    return null;
  };

  renderMetricLevels = (metric: KpiMetric, errors: Modified, modified: Modified): JSX.Element => {
    const { isModifying } = this.props;

    const columns: any[] = [
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: (priorityTitle: KpiPriority['title'], row: MetricLevelRow) => <span>{ priorityTitle }</span>,
        width: 120,
        ellipsis: true,
        fixed: 'left',
        hidden: metric?.kpi_priority_set?.reference === 'na',
      },
      {
        title: 'Colour',
        dataIndex: 'color',
        key: 'color',
        render: (priorityTitle: KpiPriority['title'], row: MetricLevelRow) => {
          return (
            <span
              style={{
                background: row?.color || '#ffffff',
                height: 20,
                width: 20,
                position: 'relative',
                outline: 'none',
                float: 'left',
                borderRadius: 4,
              }}
            />
          );
        },
        width: 100,
        ellipsis: true,
        hidden: metric?.kpi_priority_set?.reference === 'na',
      },
      {
        title: 'Priority Description',
        dataIndex: 'priority_description',
        key: 'priority_description',
        render: (priorityDescription: KpiPriority['description']) => {
          return (
            <div
              className="Editor Editor--disabled Editor--read-only d-b pY-5 pX-10 bd border-antd bdrs-2"
              dangerouslySetInnerHTML={{ __html: priorityDescription || '-' }}
            />
          );
        },
        width: 350,
        ellipsis: true,
        hidden: metric?.kpi_priority_set?.reference === 'na',
      },
      {
        dataIndex: 'description',
        key: 'description',
        title: this.renderColumnTitle(
          ['Service Level', 'Description'],
          'Please describe this service level in a few sentences.',
          true
        ),
        render: (description: KpiMetricLevel['description'], row: MetricLevelRow) => {
          const hasError: boolean = this.hasMetricLevelError(errors, row.id, 'description');
          const isModified: boolean = this.isModifiedMetricLevel(modified, row.id, 'description');

          return (
            <ReactQuill
              readOnly={ !!isModifying }
              className={ classNames('Editor bd bdrs-2', {
                'border-danger': hasError,
                'border-antd': !isModified && !hasError,
                'border-warning': isModified && !hasError,
              }) }
              theme="snow"
              bounds={ '.Editor' }
              value={ description || '' }
              onChange={ _.debounce((__, ___, source: string, editor) => {
                if (source === 'user') {
                  this.modifyMetricLevel(metric, row.id, 'description', editor.getHTML());
                }
              }, 500) }
            />
          );
        },
        width: 370,
        ellipsis: true,
      },
      {
        dataIndex: 'target_description',
        key: 'target_description',
        title: this.renderColumnTitle(
          ['Service Level', 'Target Description'],
          'Please describe what is being measured.',
          true
        ),
        render: (targetDescription: KpiMetricLevel['target_description'], row: MetricLevelRow) => {
          const hasError: boolean = this.hasMetricLevelError(errors, row.id, 'target_description');
          const isModified: boolean = this.isModifiedMetricLevel(modified, row.id, 'target_description');

          return (
            <ReactQuill
              readOnly={ !!isModifying }
              className={ classNames('Editor bd bdrs-2', {
                'border-danger': hasError,
                'border-antd': !isModified && !hasError,
                'border-warning': isModified && !hasError,
              }) }
              theme="snow"
              bounds={ '.Editor' }
              value={ targetDescription || '' }
              onChange={ _.debounce((__, ___, source: string, editor) => {
                if (source === 'user') {
                  this.modifyMetricLevel(metric, row.id, 'target_description', editor.getHTML());
                }
              }, 500) }
            />
          );
        },
        width: 370,
        ellipsis: true,
      },
    ];

    if (!!metric.kpi_metric_type) {
      const specificLevelColumn = this.renderMeasureColumn(metric, modified, errors);
      if (!!specificLevelColumn) {
        columns.push(specificLevelColumn);
      }
    }

    // Add service level target column
    columns.push(
      {
        dataIndex: 'target',
        key: 'target',
        title: this.renderColumnTitle(
          ['Service Level', 'Target'],
          'Please set the percentage of KPIs that must be met.',
          true
        ),
        render: (target: KpiMetricLevel['target'], row: MetricLevelRow) => {
          const hasError: boolean = this.hasMetricLevelError(errors, row.id, 'target');
          const isModified: boolean = this.isModifiedMetricLevel(modified, row.id, 'target');

          return (
            <InputNumber
              min={ 0 }
              max={ 100 }
              disabled={ !!isModifying }
              className={ classNames('Field', {
                'Field--has-error border-danger': hasError,
                'Field--has-warning border-warning': isModified && !hasError,
              }) }
              value={ target || 0 }
              style={{ width: '100%' }}
              formatter={ value => `${value}%` }
              parser={ value => +value!.replace('%', '') }
              onChange={ (value: number) => {
                const newValue = (_.isNumber(value) && !!value) ? value : 0;
                this.modifyMetricLevel(metric, row.id, 'target', newValue);
              } }
            />
          );
        },
        width: 150,
        ellipsis: true,
      }
    );

    const scrollX: number = columns.reduce((acc, curr) => acc += curr.width, 0);

    const dataSource: MetricLevelRow[] = metric.kpi_metric_levels?.map((level: KpiMetricLevel) => {
      return {
        id: level.id,
        priority: level.kpi_priority?.title,
        color: level.kpi_priority?.color,
        priority_description: level.kpi_priority?.description,
        description: level.description,
        target: level.target,
        target_description: level.target_description,
        measure: level.measure,
      };
    });

    return (
      <>
        { this.renderDivider({ id: 'level_devider', type: 'table', config: { column_span: 12, text: '', orientation: 'left' } }) }
        <Table
          className="MetricLevelsTable"
          columns={ columns.filter((_column: any) => !_column?.hidden) }
          dataSource={ dataSource }
          size="small"
          rowKey={ (row: MetricLevelRow) => `metric_level_${row.id}` }
          scroll={{
            x: scrollX,
          }}
          pagination={ false }
        />
      </>
    );
  };

  renderActions = (metricRow: KpiMetric, hasError: boolean, isModified: boolean): JSX.Element => {
    const { canApprove, canEdit, isModifying, isPublishing, isVersioning } = this.props;
    const { modifiedKpiMetric, originalKpiMetric } = this.state;

    const isEditMode: boolean = !modifiedKpiMetric?.isNewMetric;
    const isNew: boolean = !!modifiedKpiMetric?.isNewMetric;

    let disabled: boolean = true;
    let tooltip: string[] | undefined;

    if (!canEdit) {
      disabled = true;
      tooltip = [`You don't have permissions to edit metric`];
    } else if (isEditMode || isNew) {
      disabled = hasError || !isModified;
      tooltip = hasError ? ['Please resolve all errors'] : !isModified ? ['No unsaved changes'] : ['You have unsaved changes'];
    }

    const onClickHandler = (): void => {
      if (this.props.onEdit && isEditMode) {
        this.props.onEdit(modifiedKpiMetric.id, modifiedKpiMetric);
      } else if (this.props.onCreate && isNew) {
        this.props.onCreate(modifiedKpiMetric);
      }
    };

    const menuItems: Array<{ key: string, onClick: () => void, danger?: boolean, node: React.ReactNode, disabled?: boolean | string[], isLoading?: boolean }> = [{
      key: 'primary_action',
      node: isEditMode ? 'Save' : isNew ? 'Create' : 'Save',
      onClick: onClickHandler,
      disabled: disabled ? tooltip : false
    }];

    if (canApprove && metricRow.status !== MetricStatus.Approved) {
      menuItems.push({
        key: 'metric_status',
        onClick: () => this.onChangeStatus(metricRow.id, metricRow.status === MetricStatus.Draft ? MetricStatus.Pending : MetricStatus.Approved),
        node: <span>{ metricRow.status === MetricStatus.Draft ? 'Pending Approval' : 'Approve Metric' }</span>,
      });
    }

    if (originalKpiMetric && originalKpiMetric.version_status === 'PUBLISHED') {
      menuItems.push({
        key: 'metric_new_version',
        node: <>{ 'New Version' }</>,
        onClick: () => this.props.onVersion && this.props.onVersion(originalKpiMetric.id),
        isLoading: !!isModifying || !!isVersioning || !!isPublishing
      });
    }

    if (originalKpiMetric && originalKpiMetric.version_status !== 'PUBLISHED') {
      menuItems.push({
        key: 'metric_publish',
        node: <>{ 'Publish' }</>,
        onClick: () => this.props.onPublish && this.props.onPublish(originalKpiMetric.id),
        isLoading: !!isModifying || !!isVersioning || !!isPublishing
      });
    }

    return (
      <Row className="ExternalControls">
        <Col flex={ 1 } />
        <Col>
          { !_.isEmpty(menuItems) && <div className="d-if"><Dropdown actions={ menuItems } /></div> }
        </Col>
      </Row>
    );
  };

  renderMetric = (): JSX.Element => {
    const { kpiMetric, kpiMetricRecordTypes, kpiMetricTypes, kpiPrioritySets, isModifying, isLoading } = this.props;
    const { modifiedKpiMetric } = this.state;

    const errors: Modified = this.getErrors(modifiedKpiMetric);
    const modified: Modified = this.getModified(modifiedKpiMetric, kpiMetric);

    let kpiPrioritySetDisabled = false;

    const metricPrioritySet: KpiPrioritySet | undefined = kpiPrioritySets.find(kpiPrioritySet => kpiPrioritySet.id === modifiedKpiMetric?.kpi_priority_set_id);

    const kpiMetricRecordTypesGrouped: any[] = [];
    kpiMetricRecordTypes.map((kpiMetricRecordType: KpiMetricRecordType) => {
      const kpiMetricRecordTypeGrouped = kpiMetricRecordTypesGrouped.find(_item => _item.bundle === kpiMetricRecordType.bundle);
      if(!kpiMetricRecordTypeGrouped) {
        kpiMetricRecordTypesGrouped.push({
          label: _.startCase(_.camelCase(kpiMetricRecordType.bundle)),
          bundle: kpiMetricRecordType.bundle,
          options: [{
            bundle: kpiMetricRecordType.bundle,
            type: kpiMetricRecordType.type,
            label: kpiMetricRecordType.label
          }]
        });
      } else {
        kpiMetricRecordTypeGrouped.options.push({
          bundle: kpiMetricRecordType.bundle,
          type: kpiMetricRecordType.type,
          label: kpiMetricRecordType.label
        });
      }
    });

    const compatibleMetricTypes: any[] = [];
    const compatiblePrioritySets: KpiPrioritySet[] = this.getRecordCompatiblePrioritySet(modifiedKpiMetric);
    if (modifiedKpiMetric.kpi_metric_record_type) {
      kpiMetricTypes.map((_kpiMetricType: KpiMetricType) => {
        if (_.has(_kpiMetricType, 'config.entities')) {
          if (_kpiMetricType.config.entities.find((entity: any) => entity.type === modifiedKpiMetric.kpi_metric_record_type)) {
            compatibleMetricTypes.push(_kpiMetricType);
          }
        }
      });
    }

    return (
      <BlockingSpinner isLoading={ !!isLoading }>
        <div id='FormBuilder'>
          <div className="ant-collapse ant-collapse-icon-position-right Form-Group Form-Group--open false false">
            <div className="Form-Grid p-15">
              <FieldWrapper
                id={ 'kpi_metric_record_type' }
                col={ 3 }
                label={ this.renderColumnTitle(['Record Type'], 'Select record type. This will determine what are the compatible Metric types and Priority Sets.', true) }
                errors={ errors.kpi_metric_record_type ? ['Please select record type'] : [] }
                required
                border
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={ false }
                  disabled={ !!isModifying }
                  style={{ width: '100%' }}
                  placeholder={ '-' }
                  className={ classNames('Select-Field', {
                    'Select-Field--has-error border-danger': this.hasMetricError(errors, 'kpi_metric_record_type'),
                    'Select-Field--has-warning border-warning': this.isModifiedMetric(modified, 'kpi_metric_record_type') && !this.hasMetricError(errors, 'kpi_metric_record_type'),
                  }) }
                  filterOption={ (input: string, option: any) => {
                    return !!kpiMetricRecordTypes.find((record: KpiMetricRecordType) => record.label.toLowerCase() === option.children.toLowerCase() && record.label.toLowerCase().includes(input.toLowerCase()));
                  } }
                  value={ modifiedKpiMetric.kpi_metric_record_type }
                  onChange={ (value) => this.modifyMetric(modifiedKpiMetric.id, 'kpi_metric_record_type', value) }
                >
                  { kpiMetricRecordTypesGrouped.map( (item: any, index: number) => {
                    return (
                      <Select.OptGroup key={ item.label + index } label={ item.label }>
                        { item.options.map((option: any) => {
                          return (
                            <Select.Option value={ option.type } key={ `${option.bundle}_${option.type}` }>
                              { option.label }
                            </Select.Option>
                          );
                        }) }
                      </Select.OptGroup>
                    );
                  }) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                id={ 'kpi_metric_type_id' }
                col={ 3 }
                label={ this.renderColumnTitle(['Type'], 'Select the metric type. This will determine what metrics are checked and how a pass/fail is determined.', true) }
                errors={ errors.kpi_metric_type_id ? ['Please select metric type'] : [] }
                required
                border
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={ false }
                  disabled={ !!isModifying || !modifiedKpiMetric.kpi_metric_record_type }
                  style={{ width: '100%' }}
                  placeholder={ '-' }
                  className={ classNames('Select-Field', {
                    'Select-Field--has-error border-danger': this.hasMetricError(errors, 'kpi_metric_type_id'),
                    'Select-Field--has-warning border-warning': this.isModifiedMetric(modified, 'kpi_metric_type_id') && !this.hasMetricError(errors, 'kpi_metric_type_id'),
                  }) }
                  filterOption={ (input: string, option: any) => {
                    return !!compatibleMetricTypes.find((record: KpiMetricType) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()));
                  } }
                  value={ modifiedKpiMetric.kpi_metric_type_id }
                  onChange={ (value) => this.modifyMetric(modifiedKpiMetric.id, 'kpi_metric_type_id', value) }
                >
                  { orderListByKey(compatibleMetricTypes, 'title').map( (kpiMetricType: KpiMetricType) => (
                    <Select.Option key={ kpiMetricType.id } value={ kpiMetricType.id }>{ kpiMetricType.title }</Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                id={ 'kpi_priority_set_id' }
                col={ 3 }
                label={ this.renderColumnTitle(['Priority Set'], 'Select the priority set. The levels below will automatically adapt based on the selected set.', true) }
                errors={ errors.kpi_priority_set_id ? ['Please select kpi metric priority set'] : [] }
                required
                border
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={ false }
                  disabled={ kpiPrioritySetDisabled || !!isModifying || !modifiedKpiMetric.kpi_metric_record_type }
                  style={{ width: '100%' }}
                  placeholder={ '-' }
                  className={ classNames('Select-Field', {
                    'Select-Field--has-error border-danger': this.hasMetricError(errors, 'kpi_priority_set_id'),
                    'Select-Field--has-warning border-warning': this.isModifiedMetric(modified, 'kpi_priority_set_id') && !this.hasMetricError(errors, 'kpi_priority_set_id'),
                  }) }
                  filterOption={ (input: string, option: any) => {
                    return !!kpiPrioritySets.find((record: KpiPrioritySet) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()));
                  } }
                  value={ modifiedKpiMetric.kpi_priority_set_id }
                  onChange={ (value) => this.onChangePrioritySet(modifiedKpiMetric.id, value, !!modifiedKpiMetric.kpi_priority_set_id) }
                >
                  { orderListByKey(compatiblePrioritySets, 'title').map( (kpiPrioritySet: KpiPrioritySet) => (
                    <Select.Option key={ kpiPrioritySet.id } value={ kpiPrioritySet.id } disabled={ _.isEmpty(kpiPrioritySet.kpi_priorities) }>
                      <Tooltip title={ kpiPrioritySet.description }>
                        { kpiPrioritySet.title }
                      </Tooltip>
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                id={ 'reporting_period' }
                col={ 3 }
                label={ this.renderColumnTitle(['Reporting Period'], 'Select reporting period.', false) }
                errors={ errors.kpi_priority_set_id ? ['Please select kpi metric reporting period'] : [] }
                required
                border
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={ false }
                  disabled={ !!isModifying }
                  style={{ width: '100%' }}
                  placeholder={ '-' }
                  className={ classNames('Select-Field', {
                    'Select-Field--has-error border-danger': this.hasMetricError(errors, 'reporting_period'),
                    'Select-Field--has-warning border-warning': this.isModifiedMetric(modified, 'reporting_period') && !this.hasMetricError(errors, 'reporting_period'),
                  }) }
                  filterOption={ (input: string, option: any) => {
                    return !!kpiPrioritySets.find((record: KpiPrioritySet) => record.title.toLowerCase() === option.children.toLowerCase() && record.title.toLowerCase().includes(input.toLowerCase()));
                  } }
                  value={ modifiedKpiMetric.reporting_period }
                  onChange={ (value) => this.modifyMetric(modifiedKpiMetric.id, 'reporting_period', value) }
                >
                  { Object.keys(MetricReportingPeriod).map( (key: string) => (
                    <Select.Option key={ key } value={ key } >
                      { MetricReportingPeriod[key] }
                    </Select.Option>
                  )) }
                </Select>
              </FieldWrapper>
              <FieldWrapper
                id={ 'kpi_priority_set_description' }
                col={ 12 }
                label={ 'Priority Set Description' }
                border
              >
                { metricPrioritySet && !_.isEmpty(metricPrioritySet.description) ? <span className="d-b pY-5" dangerouslySetInnerHTML={{ __html: metricPrioritySet.description }}></span> : <span className="d-b pY-5">-</span> }
              </FieldWrapper>
            </div>
          </div>
          { modifiedKpiMetric.kpi_priority_set_id && this.renderMetricLevels(modifiedKpiMetric, errors, modified) }
        </div>
      </BlockingSpinner>
    );
  };

  renderDivider = (element: any): React.ReactNode => {
    return (
      <FieldWrapper
        key={ `${element.type}-${element.id}` }
        col={ element.config.column_span }
        required={ false }
        style={{ minHeight: 'inherit' }}
      >
        <Divider
          orientation={ element.config.orientation }
          text={ element.config.text }
        />
      </FieldWrapper>
    );
  };

  renderVersions = (metric: KpiMetric, versions: any[]) => {
    const items: TimelineItem[] = versions.map((item: any) => {
      const isCurrent = item.id === metric.id;
      return {
        colorClass: isCurrent ? 'Timeline--primary' : 'Timeline--default',
        node: (
          <>
            <div className="bdB pB-10">
              <span className="fw-500">{ getFormatedDate(item.created_at, undefined, true) }</span>
              <span className="mL-10"><Badge type={ item.version_status === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ `Version ${item.version || 1}` } /></span>
            </div>
            <div className="mT-10">
              <span className="p-2"><Link to={ `/workplace-services/kpi-library/metric/${item.id}` }>{ item.title }</Link></span>
            </div>
          </>
        ),
      };
    });

    return (
      <Timeline timeline={ items } />
    );
  };

  render = (): JSX.Element => {
    const { originalKpiMetric, modifiedKpiMetric } = this.state;

    const errors: Modified = this.getErrors(modifiedKpiMetric);
    const modified: Modified = this.getModified(modifiedKpiMetric, originalKpiMetric);

    const tabs = [{
      label: 'Overview',
      node: this.renderMetric()
    }];

    if (originalKpiMetric) {
      tabs.push({
        label: 'Versions',
        node: this.renderVersions(originalKpiMetric, (originalKpiMetric && originalKpiMetric.version_history) || [])
      });
    }

    return (
      <div>
        <div className="bg-tab-grey pT-30 pL-30 pR-30 pB-15 bB-2">
          <div className={ classNames('d-f', { 'jc-sb': !modifiedKpiMetric.isNewMetric, 'jc-fe': modifiedKpiMetric.isNewMetric }) }>
            { originalKpiMetric && (
              <div>
                <div>
                  <span className="fsz-lg fw-500">{ originalKpiMetric.title }</span>
                </div>
                <div className='mT-5'>
                  <span><Badge type={ originalKpiMetric.version_status === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ _.startCase(_.toLower(originalKpiMetric.version_status)) } /></span>
                  <span className="mL-10"><Badge type={ BadgeType.Disabled } text={ `Version ${originalKpiMetric.version}` } /></span>
                </div>
              </div>
            ) }
            { this.renderActions(modifiedKpiMetric, !_.isEmpty(errors), !_.isEmpty(modified)) }
          </div>
        </div>
        <TabView
          ref={ tabViewRef => this.tabViewRef = tabViewRef }
          tabs={ tabs }
        />
      </div>
    );
  };
};

export default KpiMetricForm;
