// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import { RestrictionHoC } from 'components/restriction';
import Base64Image from 'components/base64-image';
import Input from 'components/input';
import {
  Form,
  Row,
  Col,
  Typography,
  Tooltip,
  Select,
  TreeSelect,
  Upload,
  Spin,
} from 'antd';

// Icons
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import ClientState from 'store/Client/State.interface';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";
import { setClientSetting, setClientLogotype } from 'store/Client/Actions';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';
import {
  getLocaleName,
  getSetting,
  getClientSetting,
  AVAILABLE_LOCALES,
  AVAILABLE_DATE_FORMATS,
  AVAILABLE_NUMBER_FORMATS,
  AVAILABLE_TIME_PERIODS,
  COST_FIELD_VALIDATION_OPTIONS,
  FIELD_DESCRIPTIONS,
  FALLBACKS
} from 'services/settings';

// Utils
import { pad, isNumeric } from 'utils/utils';

// Styles
import 'assets/styles/_layout.scss';

const months: Month[] = [
  {
    'name': 'January',
    'days': 31,
  },
  {
    'name': 'February',
    'days': 28,
  },
  {
    'name': 'March',
    'days': 31,
  },
  {
    'name': 'April',
    'days': 30,
  },
  {
    'name': 'May',
    'days': 31,
  },
  {
    'name': 'June',
    'days': 30,
  },
  {
    'name': 'July',
    'days': 31,
  },
  {
    'name': 'August',
    'days': 31,
  },
  {
    'name': 'September',
    'days': 30,
  },
  {
    'name': 'October',
    'days': 31,
  },
  {
    'name': 'November',
    'days': 30,
  },
  {
    'name': 'December',
    'days': 31,
  },
];
const API: Api = new Api();
const { Text } = Typography;
const { Option } = Select;

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

interface Month {
  name: string;
  days: number;
};

interface Measurement {
  id: number;
  title: string;
  unit: string;
  symbol: string;
  conversion: string;
};

interface AssetMaintenancePlan {
  id: number;
  type: string;
  bundle: string;
  title: string;
  parent_id: number;
  values: any;
  children: any[];
  order: number;
};

interface OpexCoaCategory {
  id: number;
  title: string;
  type: string;
  bundle: string;
  reference: string;
  children: OpexCoaCategory[];
};

interface Props {
  permissions: UserPermissions;
  client: ClientState;
  intl: IntlShape;
  setClientSetting(client_id: number, satting: string, value: any): Promise<any>;
  setClientLogotype(client_id: number, logotype: any): Promise<any>;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  logotype: any;
  currencies: Currency[];
  measurements: Measurement[];
  assetMaintenancePlans: AssetMaintenancePlan[];
  helpdeskCoaCategories: OpexCoaCategory[];
  financialYears: any[];
  areaCategories: any[];
  ownedAreaFields: any[];
  leaseAreaFields: any[];
  isLoadingSettings: boolean;
  isUploadingLogotype: boolean;
};

const appendOpexCoaChildren = (children: any, values: any = []) => {

  // Prevent nesting
  if (_.isEmpty(children)) return null;

  return children.map((childEntity: any) => {

    return {
      'id': childEntity.id,
      'key': childEntity.id,
      'value': childEntity.id,
      'title': childEntity.title,
      'children': appendOpexCoaChildren(childEntity.children, values)
    };
  });
};

const generateOpexCoaList = (data: OpexCoaCategory[] = []): any[] => {

  if (_.isEmpty(data)) {
    return [];
  }

  return data.map((entity: any) => {

    return {
      'id': entity.id,
      'key': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendOpexCoaChildren(entity.children)
    };
  });
};

class GeneralSettings extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    logotype: null,
    currencies: [],
    measurements: [],
    assetMaintenancePlans: [],
    helpdeskCoaCategories: [],
    financialYears: [],
    areaCategories: [],
    ownedAreaFields: [],
    leaseAreaFields: [],
    isLoadingSettings: true,
    isUploadingLogotype: false,
  };

  componentDidMount = async () => {
    const { client } = this.props;
    this.mounted = true;

    try {

      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'General Settings', path: '/admin/general-settings' },
        { title: 'Overview', path: null },
      ], false);

      const assetMaintenancePlans = await API.get(`client/${client.client_id}/admin/content-manager/categories/asset-maintenance-plan`);
      const helpdeskCoaCategories = await API.get(`client/${client.client_id}/admin/content-manager/categories/opex-coa`);
      const currencies = await API.get(`client/${client.client_id}/admin/currencies`);
      const measurements = await API.get(`client/${client.client_id}/available_measurements`);
      const financialYears = await API.get(`client/${client.client_id}/admin/content-manager/categories/financial-year`);
      const areaCategories = await API.get(`client/${client.client_id}/admin/content-manager/categories/space-category`);
      const ownedAreaFields = await API.get(`client/${client.client_id}/admin/entity/fields/record/owned`);
      const leaseAreaFields = await API.get(`client/${client.client_id}/admin/entity/fields/record/lease`);

      this.mounted && this.setState({
        currencies: currencies,
        measurements: measurements,
        assetMaintenancePlans: assetMaintenancePlans.data,
        helpdeskCoaCategories: helpdeskCoaCategories.data,
        financialYears: financialYears ? financialYears.data : [],
        areaCategories: areaCategories ? areaCategories.data : [],
        ownedAreaFields: ownedAreaFields ? ownedAreaFields : [],
        leaseAreaFields: leaseAreaFields ? leaseAreaFields : [],
      });

    } catch(error) {
      Notification('error', '', 'Failed to load settings');
    } finally {
      this.mounted && this.setState({
        isLoadingSettings: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  setSettingAttribute = (key: string, value: string | number | null) => {
    const { client, setClientSetting } = this.props;
    this.setState({
      isLoadingSettings: true
    }, () => {
      setClientSetting(client.client_id, key, value)
        .then(() => {
          Notification('success', '', 'Successfully updated settings');
        })
        .catch((error) => {
          console.log(error);
          Notification('error', '', 'Failed to update settings');
        })
        .finally(() => {
          this.mounted && this.setState({
            isLoadingSettings: false
          });
        });
    });
  };

  handleUploadLogotype = (client_id: number, logotype: any) => {
    const { setClientLogotype } = this.props;
    const form = new FormData();

    form.append('logotype', logotype.file);

    this.setState({
      isLoadingSettings: true
    });

    return setClientLogotype(client_id, form)
      .then(() => {
        Notification('success', '', 'Successfully uploaded logotype');
      })
      .catch((error) => {
        console.log(error);
        Notification('error', '', 'Failed to upload logotype');
      })
      .finally(() => {
        this.mounted && this.setState({
          isLoadingSettings: false
        });
      });
  };

  render = () => {
    const { client } = this.props;
    const {
      isLoadingSettings,
      currencies,
      measurements,
      assetMaintenancePlans,
      helpdeskCoaCategories,
      financialYears,
      areaCategories,
      ownedAreaFields,
      leaseAreaFields,
    } = this.state;

    const active_session = getSetting(client.settings, 'active_session');
    const number_format = getSetting(client.settings, 'number_format');
    const date_format = getSetting(client.settings, 'date_format');
    const locale = getSetting(client.settings, 'locale');
    const currency = getSetting(client.settings, 'currency');
    const measurement = getSetting(client.settings, 'measurement');
    const logotype = getSetting(client.settings, 'logotype');
    const asset_maintenance_plan = getSetting(client.settings, 'asset_maintenance_plan');
    const helpdesk_category_coa = getSetting(client.settings, 'helpdesk_category_coa');
    const budget_auto_creation_offset = getSetting(client.settings, 'budget_auto_creation_offset');
    const financial_year_start_date = getSetting(client.settings, 'financial_year_start_date');
    const cost_field_validation = getSetting(client.settings, 'cost_field_validation');

    const active_session_placeholder = `Default: ${AVAILABLE_TIME_PERIODS[getClientSetting('active_session')]}`;
    const number_format_placeholder = `Default: ${AVAILABLE_NUMBER_FORMATS[getClientSetting('number_format')]}`;
    const date_format_placeholder = `Default: ${ getClientSetting('date_format') }`;
    const locale_placeholder = `Default: ${ getLocaleName(getClientSetting('locale')) }`;
    const cost_field_validation_placeholder = `Default: ${ getClientSetting('cost_field_validation') }`;

    const currency_placeholder =  `Default: British Pound (GBP)`;
    const measurement_placeholder = `Default: Square Meters (SQM)`;

    const availableDays = financial_year_start_date ? months[Number(financial_year_start_date.split('-')[0])-1].days : 28;
    const helpdeskCoaCategoryOptions = generateOpexCoaList(helpdeskCoaCategories);

    const getAssetMaintenancePlanValue = () => {
      // if data is loading, don't display value
      if (isLoadingSettings) return undefined;

      // the value is stored as a string, need to convert it to a number
      if (typeof asset_maintenance_plan !== 'number' && isNumeric(asset_maintenance_plan)) {
        return Number(asset_maintenance_plan);
      }

      return asset_maintenance_plan || undefined;
    };

    const getHelpdeskCategoryCoaValue = () => {
      // if data is loading, don't display value
      if (isLoadingSettings) return undefined;

      // the value is stored as a string, need to convert it to a number
      if (typeof helpdesk_category_coa !== 'number' && isNumeric(helpdesk_category_coa)) {
        return Number(helpdesk_category_coa);
      }

      return helpdesk_category_coa || undefined;
    };

    return (
      <div className='Layout-box'>
        <div className="pT-50 pB-50" style={{ maxWidth: 700, margin: '0 auto' }}>
          <Row className='mT-50'>
            <Col span={24}>
              <Form layout={'vertical'}>
                <Form.Item label={ <Text strong>Name</Text> }>
                  <Tooltip placement='topLeft' title={ "You can't change this value" }>
                      <div>
                        <Input isDisabled value={ client.name || '' } isPlain />
                      </div>
                  </Tooltip>
                </Form.Item>
                <Form.Item
                  style={{ minHeight: 100 }}
                  label={ <><Text strong>Logotype</Text><p className="mL-5 fsz-xs">(Allowed file types: <b>PNG</b>)</p></> }
                >
                  { isLoadingSettings ? (
                    <Spin indicator={ <LoadingOutlined style={{ fontSize: 24 }} spin /> } />
                  ) : (
                    <Upload
                      accept='.png'
                      name="logotype"
                      className="cur-p"
                      multiple={ false }
                      showUploadList={ false }
                      customRequest={ (logotype: any) => this.handleUploadLogotype(client.client_id, logotype) }
                      beforeUpload={ (file: any) => {
                        if (file.type !== 'image/png') {
                          Notification('error', '', 'You can only upload PNG files');
                          return false;
                        }
                        const isLt50M = file.size / 1024 / 1024 < 20;
                        if (!isLt50M) {
                          Notification('error', '', "Image can't be greater than 20MB");
                          return false;
                        }

                        return true;
                      } }
                    >
                      { logotype ? (
                          <Base64Image client_id={ client.client_id } image_id={ logotype } />
                        ) : (
                          <img alt='Logotype' width='auto' height='50px' src={ '/pacs-logo.png' } />
                        )
                      }
                    </Upload>
                  ) }
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Session Duration' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['active_session'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ active_session || undefined }
                placeholder={ active_session_placeholder }
                style={{ width: 150 }}
                onChange={value => {
                  this.setSettingAttribute('active_session', value);
                }}>
                { Object.keys(AVAILABLE_TIME_PERIODS).map((_activeSession: string) => {
                  const isDefault = !!FALLBACKS['active_session'] && FALLBACKS['active_session'] === _activeSession;
                  return <Option key={_activeSession} value={_activeSession}>{ `${AVAILABLE_TIME_PERIODS[_activeSession]} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Number Format' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['number_format'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ number_format || undefined }
                placeholder={ number_format_placeholder }
                style={{ width: 350 }}
                onChange={value => {
                  this.setSettingAttribute('number_format', value);
                }}>
                { Object.keys(AVAILABLE_NUMBER_FORMATS).map((_numberFormat: string) => {
                  const isDefault = !!FALLBACKS['number_format'] && FALLBACKS['number_format'] === _numberFormat;
                  return <Option key={_numberFormat} value={_numberFormat}>{ `${AVAILABLE_NUMBER_FORMATS[_numberFormat]} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Date Format' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['date_format'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ date_format || undefined }
                placeholder={ date_format_placeholder }
                style={{ width: 200 }}
                onChange={value => {
                  this.setSettingAttribute('date_format', value);
                }}>
                { AVAILABLE_DATE_FORMATS.map((_dateFormat: string) => {
                  const isDefault = !!FALLBACKS['date_format'] && FALLBACKS['date_format'] === _dateFormat;
                  return <Option key={ _dateFormat } value={ _dateFormat }>{ `${_dateFormat} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'System Language' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['locale'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ locale || undefined }
                placeholder={ locale_placeholder }
                style={{ width: 200 }}
                onChange={value => {
                  this.setSettingAttribute('locale', value);
                }}>
                { AVAILABLE_LOCALES.map((_locale: string) => {
                  const isDefault = !!FALLBACKS['locale'] && FALLBACKS['locale'] === _locale;
                  return <Option key={ _locale } value={ _locale }>{ `${getLocaleName(_locale)} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Currency' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['currency'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                showSearch
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                value={ isLoadingSettings ? undefined : currency || undefined }
                placeholder={ currency_placeholder }
                style={{ width: 250 }}
                onChange={value => {
                  this.setSettingAttribute('currency', value);
                }}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                { currencies.map((_currency: Currency) => {
                  return <Option key={ _currency.code } value={ _currency.code }>{ `${_currency.title} (${_currency.code})` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Measurement' }
              </Text>
              { !!FIELD_DESCRIPTIONS['measurement'] &&
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['measurement'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip> }
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ measurement || undefined }
                placeholder={ measurement_placeholder }
                style={{ width: 250 }}
                onChange={value => {
                  this.setSettingAttribute('measurement', value);
                }}>
                { measurements.map((_measurement: Measurement, index: number) => {
                  return <Option key={ index } value={ _measurement.unit }>{ `${_measurement.title} (${_measurement.unit})` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Asset Maintenance Plan' }
              </Text>
              { !!FIELD_DESCRIPTIONS['asset_maintenance_plan'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['asset_maintenance_plan'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                value={ getAssetMaintenancePlanValue() }
                style={{ width: 250 }}
                placeholder={ 'Asset Maintenance Plan' }
                onChange={ (value) => {
                  this.setSettingAttribute('asset_maintenance_plan', value);
                } }
              >
                { assetMaintenancePlans.map((maintenancePlan) => {
                  return <Option key={ maintenancePlan.id } value={ maintenancePlan.id }>{ maintenancePlan.title }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Helpdesk COA Category' }
              </Text>
              { !!FIELD_DESCRIPTIONS['helpdesk_category_coa'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['helpdesk_category_coa'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col>
              <TreeSelect
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                treeData={ helpdeskCoaCategoryOptions }
                value={ getHelpdeskCategoryCoaValue() }
                dropdownMatchSelectWidth={ false }
                style={ { width: 350 } }
                placeholder={ 'Helpdesk COA Category' }
                onChange={ (value) => {
                  this.setSettingAttribute('helpdesk_category_coa', value);
                } }
              />
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Financial Year Start Date' }
              </Text>
              { !!FIELD_DESCRIPTIONS['financial_year_start_date'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['financial_year_start_date'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col style={{ minWidth: 250 }}>
              <Tooltip
                overlayClassName={ classNames({ 'd-n': _.isEmpty(financialYears) }) }
                placement="top"
                title={ 'This config cannot be changed. Financial years have already been generated.' }
              >
                <div className="d-f jc-sb">
                  <Select
                    loading={ isLoadingSettings }
                    disabled={ isLoadingSettings || !_.isEmpty(financialYears) }
                    style={{ width: 120 }}
                    placeholder={ 'Month' }
                    value={ financial_year_start_date ? financial_year_start_date.split('-')[0] : undefined }
                    onChange={ (month: string) => {

                      const monthOption = months[Number(month) - 1];
                      let day = '01';

                      if (financial_year_start_date) {
                        day = financial_year_start_date.split('-')[1];
                      }

                      if (Number(day) > monthOption?.days) {
                        day = '01';
                      }

                      this.setSettingAttribute('financial_year_start_date', `${month}-${day}`);
                    } }
                  >
                    { months.map((month: Month, index: number) => {
                      return <Option key={ index } value={ pad(index+1) }>{ month.name }</Option>;
                    }) }
                  </Select>
                  <Select
                    loading={ isLoadingSettings }
                    disabled={ isLoadingSettings || !financial_year_start_date || !_.isEmpty(financialYears) }
                    style={{ width: 120 }}
                    placeholder={ 'Day' }
                    value={ financial_year_start_date ? Number(financial_year_start_date.split('-')[1]) : undefined }
                    onChange={ (day: number) => {
                      const month = financial_year_start_date.split('-')[0];
                      this.setSettingAttribute('financial_year_start_date', `${month}-${pad(day)}`);
                    } }
                  >
                    { _.times(availableDays).map((day: number) => {
                      return <Option key={ day+1 } value={ day+1 }>{ day+1 }</Option>;
                    }) }
                  </Select>
                </div>
              </Tooltip>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Budget Auto Creation Offset' }
              </Text>
              { !!FIELD_DESCRIPTIONS['budget_auto_creation_offset'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['budget_auto_creation_offset'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                value={ budget_auto_creation_offset || undefined }
                style={{ width: 250 }}
                placeholder={ 'Budget Auto Creation Offset' }
                onChange={ (month: number) => {
                  this.setSettingAttribute('budget_auto_creation_offset', month);
                } }
              >
                { _.times(12).map((month: number) => {
                  return <Option key={ month+1 } value={ month+1 }>{ month+1 }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Cost Field Validation' }
              </Text>
              <Tooltip
                className="mL-5"
                placement="right"
                title={ FIELD_DESCRIPTIONS['cost_field_validation'] }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            </Col>
            <Col>
              <Select
                loading={ isLoadingSettings }
                disabled={ isLoadingSettings }
                defaultValue={ cost_field_validation || undefined }
                placeholder={ cost_field_validation_placeholder }
                style={ { width: 150 } }
                onChange={ value => {
                  this.setSettingAttribute('cost_field_validation', value);
                } }
              >
                { Object.keys(COST_FIELD_VALIDATION_OPTIONS).map((_costFieldValidationOption: string) => {
                  const isDefault = !!FALLBACKS['cost_field_validation'] && FALLBACKS['cost_field_validation'] === _costFieldValidationOption;
                  return <Option key={ _costFieldValidationOption } value={ _costFieldValidationOption }>{ `${COST_FIELD_VALIDATION_OPTIONS[_costFieldValidationOption]} ${isDefault ? '(Default)' : ''}` }</Option>;
                }) }
              </Select>
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Owned Area Field' }
              </Text>
              { !!FIELD_DESCRIPTIONS['owned_area_field'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['owned_area_field'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col>
              { areaCategories.map((_areaCategory: any) => {
                const areaFieldValue = getSetting(client.settings, `owned_area_field_${_.snakeCase(_areaCategory?.title)}`);
                return (
                  <div className="d-f jc-sb mT-10" key={_.snakeCase(_areaCategory?.title)}>
                    <div className='mR-10 pT-5'><Text strong>{ _areaCategory?.title }</Text></div>
                    <div>
                      <Select
                        loading={ isLoadingSettings }
                        disabled={ isLoadingSettings }
                        value={ areaFieldValue }
                        style={{ width: 200 }}
                        placeholder={ 'Area Field' }
                        onChange={ (value) => {
                          this.setSettingAttribute(`owned_area_field_${_.snakeCase(_areaCategory?.title)}`, value);
                        } }
                      >
                        { ownedAreaFields.filter((_areaFieldOption: any) => _areaFieldOption.area_category === _.snakeCase(_areaCategory?.title)).map((_areaFieldOption: any) => <Option key={ _areaFieldOption.field_id } value={ _.snakeCase(_areaFieldOption.field_id) }>{ _areaFieldOption.label }</Option> ) }
                      </Select>
                    </div>
                  </div>
                );
              }) }
            </Col>
          </Row>
          <Row className='mT-50'>
            <Col flex='2'>
              <Text strong>
                { 'Lease Area Field' }
              </Text>
              { !!FIELD_DESCRIPTIONS['lease_area_field'] &&
                <Tooltip
                  className="mL-5"
                  placement="right"
                  title={ FIELD_DESCRIPTIONS['lease_area_field'] }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </Col>
            <Col>
              { areaCategories.map((_areaCategory: any) => {
                const areaFieldValue = getSetting(client.settings, `lease_area_field_${_.snakeCase(_areaCategory?.title)}`);
                return (
                  <div className="d-f jc-sb mT-10" key={_.snakeCase(_areaCategory?.title)}>
                    <div className='mR-10 pT-5'><Text strong>{ _areaCategory?.title }</Text></div>
                    <div>
                      <Select
                        loading={ isLoadingSettings }
                        disabled={ isLoadingSettings }
                        value={ areaFieldValue }
                        style={{ width: 200 }}
                        placeholder={ 'Area Field' }
                        onChange={ (value) => {
                          this.setSettingAttribute(`lease_area_field_${_.snakeCase(_areaCategory?.title)}`, value);
                        } }
                      >
                        { leaseAreaFields.filter((_areaFieldOption: any) => _areaFieldOption.area_category === _.snakeCase(_areaCategory?.title)).map((_areaFieldOption: any) => <Option key={ _areaFieldOption.field_id } value={ _.snakeCase(_areaFieldOption.field_id) }>{ _areaFieldOption.label }</Option> ) }
                      </Select>
                    </div>
                  </div>
                );
              }) }
            </Col>
          </Row>
        </div>
      </div>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client: store.ClientState,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setClientSetting: (client_id: number, setting: string, value: any) => dispatch(setClientSetting(client_id, setting, value)),
    setClientLogotype: (client_id: number, logotype: any) => dispatch(setClientLogotype(client_id, logotype)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(GeneralSettings), 'access_admin_general_settings'));
