// Libs
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Dropdown from 'components/dropdown';
import { Checkbox, Select, TreeSelect, Tooltip, Table, Pagination } from 'antd';
import { regionMap } from 'components/resources-table';
import Icon from '@ant-design/icons';

// Services
import { Api } from 'services/api';
import { getClientSetting } from 'services/settings';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Utils
import { nestedSet, flattenSet } from 'utils/utils';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Styles
import 'assets/styles/_layout.scss';


const API: Api = new Api();
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  originalCountries: any;
  manipulatedCountries: any;
  availableRegions: any;
  availableCurrencies: any;
  isLoading: boolean;
  isSaving: boolean;
  currentPage: number;
  filters: any;
  itemsPerPage: number;
  showFilter: boolean;
};

interface Country {
  id: number;
  title: string;
  code: string;
  symbol: string;
  in_use: boolean;
  regions: number[];
  default_currency_code?: string;
};

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

class Countries extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    originalCountries: null,
    manipulatedCountries: null,
    availableRegions: [],
    availableCurrencies: [],
    isLoading: false,
    isSaving: false,
    currentPage: 1,
    filters: {},
    itemsPerPage: 25,
    showFilter: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetch();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.match.params.type === 'countries' && prevProps.match.params.type !== this.props.match.params.type) {
      this.fetch();
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  fetch = async () => {
    const { client_id, setBreadcrumbs } = this.props;

    try {

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'General Settings', path: '/admin/general-settings' },
        { title: 'Countries', path: null },
      ], false);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const countries = await API.get(`client/${client_id}/admin/countries`);
      const availableRegions = await API.get(`client/${client_id}/available_regions`);
      const availableCurrencies = await API.get(`client/${client_id}/admin/currencies`);

      this.mounted && this.setState({
        originalCountries: _.cloneDeep(countries),
        manipulatedCountries: _.cloneDeep(countries),
        availableRegions: availableRegions,
        availableCurrencies: availableCurrencies,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  paginateItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  renderFilters = (filters: any, dataSource: any[]) => {
    return (
      <div className="d-f mB-10">
        <div className="mR-5">
          <Select
            allowClear
            style={{ width: 200, margin: 5 }}
            placeholder={ 'In Use' }
            onChange={(value: boolean | undefined) => {

              let newFilters = filters;

              if (!_.isBoolean(value) && !value) {
                delete newFilters['in_use'];
              } else {
                newFilters = _.set(filters, 'in_use', value);
              }

              this.setState({
                filters: newFilters,
                currentPage: 1,
              });
            }}
            value={ filters?.in_use }
          >
            <Option key={ 'yes' } value={ true }>{ 'Yes' }</Option>
            <Option key={ 'no' } value={ false }>{ 'No' }</Option>
          </Select>
        </div>
        <div>
          <Select
            allowClear
            mode='multiple'
            style={{ width: 200, margin: 5 }}
            placeholder={ 'Title' }
            onChange={(titles: string[]) => {

              let newFilters = filters;

              if (_.isEmpty(titles)) {
                delete newFilters['title'];
              } else {
                newFilters = _.set(filters, 'title', titles);
              }

              this.setState({
                filters: newFilters,
                currentPage: 1,
              });
            }}
            value={ filters?.title }
          >
            { flattenSet(dataSource).map((country: any, index: number) => (
              <Option key={ index } value={ country.title }>{ country?.title || '-' }</Option>
            ) ) }
          </Select>
        </div>
      </div>
    );
  };

  render = () => {
    const { client_id } = this.props;
    const {
      originalCountries,
      manipulatedCountries,
      availableRegions,
      availableCurrencies,
      isLoading,
      isSaving,
      currentPage,
      filters,
      itemsPerPage,
      showFilter,
    } = this.state;

    if (isLoading || !manipulatedCountries) {
      return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;
    }

    let dataSource = nestedSet(manipulatedCountries);

    // Filter
    if (!_.isEmpty(filters)) {
      dataSource = dataSource.filter((country: any) => {
        return Object.keys(filters).every(filterKey => {

          if (!_.has(country, filterKey)) {
            return false;
          }

          switch (filterKey) {
            case 'in_use':
              return filters[filterKey] === country[filterKey];
            case 'title':
              return filters[filterKey].includes(country[filterKey]);
            default:
              return false;
          }
        });
      });
    }

    // Paginate items
    const paginatedData = this.paginateItems(dataSource || [], currentPage, itemsPerPage);

    const columns: any[] = [
      {
        key: 'in_use',
        dataIndex: 'in_use',
        title: () => {
          const allSelected = this.state.manipulatedCountries.every((country: Country) => !!country.in_use);
          const someSelected = this.state.manipulatedCountries.some((country: Country) => !!country.in_use);

          if (!_.isEmpty(filters)) {
            return (
              <Tooltip title={ "Can't be used in combination with filters" } placement={ 'left' }>
                <Checkbox disabled />
              </Tooltip>
            );
          }

          return (
            <Tooltip key={ 'select_all' } title={ allSelected ? 'Deselect All' : 'Select All' } placement={ 'left' }>
              <Checkbox
                checked={ allSelected }
                indeterminate={ !allSelected && someSelected }
                onChange={ (event) => {
                  this.setState({
                    manipulatedCountries: dataSource?.map((country: Country) => {
                      country.in_use = event.target.checked;

                      // Set default currency
                      if (event.target.checked) {
                        if (!country.default_currency_code) {
                          country.default_currency_code = getClientSetting('currency');
                        }
                      } else {
                        country.regions = [];
                        delete country.default_currency_code;
                      }

                      return country;
                    })
                  });
                } }
              />
            </Tooltip>
          );
        },
        label: 'In Use',
        width: 100,
        align: 'center',
        render: (__: any, _country: any) => {
          return (
            <Checkbox
              checked={ _country.in_use }
              onChange={ (event) => {
                this.setState({
                  manipulatedCountries: manipulatedCountries?.map((country: Country) => {
                    if (_country.id === country.id) {
                      country.in_use = event.target.checked;

                      // Set default currency
                      if (event.target.checked) {
                        if (!_country.default_currency_code) {
                          country.default_currency_code = getClientSetting('currency');
                        }
                      } else {
                        country.regions = [];
                        delete country.default_currency_code;
                      }

                      return country;
                    }
                    return country;
                  })
                });
              } }
            />
          );
        }
      },
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Title',
        sorter: true,
        ellipsis: true,
        width: 150,
      },
      {
        key: 'code',
        dataIndex: 'code',
        title: 'Code',
        sorter: false,
        ellipsis: false,
        width: 80,
      },
      {
        key: 'dial_code',
        dataIndex: 'dial_code',
        title: 'Dial Code',
        sorter: false,
        width: 80,
        render: (__: any, _country: any) => {
          return `+${_country.dial_code}`;
        }
      },
      {
        key: 'regions',
        dataIndex: 'regions',
        title: 'Regions',
        sorter: false,
        width: 300,
        render: (__: any, _country: any) => {
          return (
            <TreeSelect
              style={{ width: 250 }}
              loading={ isLoading }
              disabled={ !_country.in_use }
              placeholder="-"
              maxTagCount={ 3 }
              dropdownMatchSelectWidth={ false }
              showCheckedStrategy={ SHOW_PARENT }
              treeCheckable
              treeData={ availableRegions ? regionMap(availableRegions) : [] }
              value={ _country.regions }
              multiple
              filterTreeNode={ (input: string, option: any) => {
                if (option) {
                  const filteredInput = input.toLocaleLowerCase();
                  const title = option.title && option.title.toLowerCase();

                  if (title.includes(filteredInput)) {
                    return true;
                  }
                }

                return false;
              } }
              onChange={(regionIds: any) => {
                this.setState({
                  manipulatedCountries: this.state.manipulatedCountries?.map((country: Country) => {
                    if (_country.id === country.id) {
                      country.regions = regionIds;
                      return country;
                    }
                    return country;
                  })
                });
              }}
            />
          );
        }
      },
      {
        key: 'currency',
        dataIndex: 'currency',
        title: 'Default Currency',
        filterable: false,
        sorter: false,
        width: 300,
        render: (__: any, _country: Country) => {
          return (
            <Select
              style={{ width: 250 }}
              allowClear={ false }
              disabled={ !_country.in_use }
              className={ 'Select-Field' }
              placeholder="-"
              value={ _country.default_currency_code || undefined }
              onSelect={ (currency: string) => {
                this.setState({
                  manipulatedCountries: this.state.manipulatedCountries?.map((country: Country) => {
                    if (_country.id === country.id) {
                      country.default_currency_code = currency;
                      return country;
                    }
                    return country;
                  })
                });
              } }
            >
              { availableCurrencies.map((currency: Currency, index: number) => (
                <Option key={ index } value={ currency.code }>{ `${currency.title} (${currency.code})` }</Option>
              )) }
            </Select>
          );
        }
      },
    ];

    return (
      <>
        <div className="d-f jc-sb ai-c mB-20 mT-5">
          <div className="pos-f" style={{ zIndex: 5, top: 100, right: 30 }}>
            <Dropdown
              actions={ [
                {
                  node: 'Save',
                  onClick: async () => {
                    try {

                      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));
                      const countries = await API.put(`client/${client_id}/admin/countries`, {
                        data: {
                          'countries': manipulatedCountries
                        }
                      });

                      this.mounted && this.setState({
                        originalCountries: _.cloneDeep(countries),
                        manipulatedCountries: _.cloneDeep(countries),
                      }, () => {
                        Notification('success', 'Saved settings.', '');
                      });

                    } catch (error) {
                      console.error('Error: ', error);
                      Notification('success', 'Failed to save settings.', '');
                    } finally {
                      this.mounted && this.setState({
                        isSaving: false
                      });
                    }
                  },
                  isLoading: isSaving,
                  disabled: _.isEqual(originalCountries, manipulatedCountries)
                }
              ] }
            />
          </div>
        </div>
        <div
          className="d-f jc-sb ai-c mB-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if mL-10">
            <span>Show</span>
            <span className="mL-10 mR-10">
              <Select
                size={ 'small' }
                onChange={(value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                }}
                defaultValue={ itemsPerPage }
              >
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </span>
            <span>Entries of <b>{ dataSource.length }</b></span>
            <span
              className={ classNames('link mL-35', { 'active': showFilter || !_.isEmpty(filters) }) }
              onClick={() => {
                this.setState({
                  showFilter: !showFilter
                });
              }}
            >
              <Icon component={FilterIcon} />
              <span> Filter</span>
            </span>
          </div>
          <div className="d-if">
            <Pagination
              showSizeChanger={ false }
              current={ currentPage }
              total={ dataSource.length }
              pageSize={ itemsPerPage }
              onChange={ page => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
        <div>
          { showFilter && this.renderFilters(filters, originalCountries) }
        </div>
        <div className='Layout-box'>
          <Table
            size={ 'small' }
            columns={ columns }
            dataSource={ paginatedData }
            pagination={ false }
          />
        </div>
      </>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(Countries), 'access_admin_general_settings'));