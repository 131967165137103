// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Form, Modal, Select, Tooltip } from 'antd';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

const API: Api = new Api();
const { Option } = Select;

interface Props {
  clientId?: number;
  runEndpoint: string;
  checkEndpoint: string;
  entities: any[];
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  isApplyingOperation: boolean;
  isLoading: boolean;
  transitions: any;
  transitionId: number | null;
  errorResponse: any[];
};

class WorkflowTransitionModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    isApplyingOperation: false,
    isLoading: false,
    transitions: null,
    transitionId: null,
    errorResponse: [],
  };

  componentDidMount = async () => {
    const { clientId, entities } = this.props;

    this.mounted = true;

    try {
      if (!clientId) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const transitions = await API.post(`client/${clientId}/bulk-operations/workflow-transition/available-transitions`, {
        entities: entities
      });

      this.mounted && this.setState({
        transitions: transitions
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleApply = async () => {
    const { checkEndpoint, runEndpoint, entities, onClose, onSuccess } = this.props;
    const { transitionId } = this.state;

    try {
      await new Promise((resolve) => this.setState({ isApplyingOperation: true }, () => resolve(null) ));

      const response = await API.post(checkEndpoint, {
        config: { transition_id: transitionId },
        entities: entities
      });

      const hasError = response.find((_error: any) => _error.status === 'error');

      if (!hasError) {
        const runResponse = await API.post(runEndpoint, {
          config: { transition_id: transitionId },
          entities: entities
        });

        Notification('success', `Workflow Transition Has Been Applied`);
        onClose();
        onSuccess(runResponse);
      }
    } catch (error) {
      console.error('Error: ', error);
      Notification('error', 'Workflow Transition Failed');
      this.setState({
        errorResponse: error.data
      });
    } finally {
      this.mounted && this.setState({
        isApplyingOperation: false
      });
    }
  };

  render = () => {
    const { isApplyingOperation, isLoading, transitionId, transitions, errorResponse } = this.state;
    const { onClose, entities } = this.props;
    return (
      <Modal
        visible
        centered
        title={ 'Workflow Transition' }
        maskClosable={ !isApplyingOperation }
        okText={ 'Add' }
        onOk={ this.handleApply }
        onCancel={ () => this.setState({
          transitionId: null
        }, onClose) }
        cancelButtonProps={{
          disabled: isApplyingOperation
        }}
        okButtonProps={{
          disabled: !transitionId,
          loading: isApplyingOperation,
        }}
        confirmLoading={ isLoading }
      >
        { !_.isEmpty(errorResponse) && (
          <div className='d-f fxd-c jc-c mT-10 mB-10'>
            <div className="fw-600 mB-10">We could not run the transition because:</div>
            { errorResponse.map((message: any, key: number) => (
              <ul className="mL-5" key={ `error_group_${key}` }>
                <li key={ `error_title_${key}` }>
                  { message.title }
                  <ul>
                    { message.errors.map((error: string, index: number) => (
                      <li className="ant-text-danger" key={ `error_message_${key}_${index}` }>{ error }</li>
                    )) }
                  </ul>
                </li>
              </ul>
            )) }
          </div>
        ) }
        <Form layout="vertical">
          <Form.Item label={ 'Transition' } required>
            <Select
              showSearch
              placeholder={ 'Select Transition' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={ (transaction: any) => {
                this.setState({
                  transitionId: transaction,
                });
              } }
              value={ transitionId }
            >
              { transitions && transitions.map((transaction: any) => {
                let _transaction = _.cloneDeep(transaction);
                if (transaction.id === 'edit_new_version' && entities.every(_entity => !!_entity.workflow_stage?.can_version)) {
                  _transaction = { ..._transaction, bulk_operation_disabled: false };
                }
                const isDisabled = !!_transaction?.bulk_operation_disabled;

                if (isDisabled) {
                  return (
                    <Option
                      key={ `workflow-transition-${transaction.id}` }
                      disabled
                    >
                      <Tooltip title={ 'Transition not available for records in this workflow stage' }>
                        { transaction.title }
                      </Tooltip>
                    </Option>
                  );
                }

                return (
                  <Option
                    key={ `workflow-transition-${transaction.id}` }
                    value={ transaction.id }
                  >
                    { transaction.title }
                  </Option>
                );
              }) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
};

export default WorkflowTransitionModal;
