// Libs
import React, { Component } from 'react';
import _, { concat } from 'lodash';

// Icons
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

// Components
import { Input, Select, Table, Tooltip } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';

// Interfaces
import {
  FormCategoryValues,
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage,
} from 'components/form/form-wrapper';

interface Props {
  field: FormField;
  state: any;
  originalState: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    values: FormCategoryValues[] | [],
    config: FormFieldConfig,
    column?: string,
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
};

interface State {
  selectedServiceType: string;
  filter: string | null;
};

const { Search } = Input;
const { Option } = Select;

class ServiceScope extends Component<Props, State> {

  state: State = {
    selectedServiceType: 'opex_coa',
    filter: null,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, config, onChange } = this.props;

    // Revert state
    onChange(field, originalState, config);

    // Remove validations for this field
    this.validate(originalState, true);
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    this.generateModifiedState(originalState, state, 'service_scope', shouldClear);
    this.generateErrorState(state, 'service_scope', shouldClear);
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(_.orderBy(pastValue, ['target_title'],['asc']), _.orderBy(newValue, ['target_title'],['asc'])) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (value: any, columnKey: string, shouldClear = false) => {
    const { setFieldErrorMessage, validate, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    const errors = validate(field, columnKey, value);

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  filterTable = (data: any[], filter: string) => {
    const collector: any = [];

    data.forEach((value: any) => {

      const check = (_value: any) => {

        if (_value.target_title.toLowerCase().includes(filter.toLowerCase())) {
          collector.push({
            'key': `${_value.target_type}-${_value.target_id}`,
            'target_bundle': _value.target_bundle,
            'target_id': _value.target_id,
            'target_path': _value.target_path,
            'target_reference': _value.target_reference,
            'target_title': _value.target_title,
            'target_type': _value.target_type,
          });
        }

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({
          'target_bundle': _value.target_bundle,
          'target_id': _value.target_id,
          'target_path': _value.target_path,
          'target_reference': _value.target_reference,
          'target_title': _value.target_title,
          'target_type': _value.target_type,
        });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  dataMapping = (data: any) => {
    return data.map((entity: any) => {
      return {
        'key': `${entity.type}-${entity.id}`,
        'target_bundle': entity.bundle,
        'target_id': entity.id,
        'target_path': entity.path,
        'target_reference': entity.reference,
        'target_title': entity.title,
        'target_type': entity.type,
        'children': !_.isEmpty(entity?.children) ? this.dataMapping(entity.children) : null,
      };
    });
  };

  render = () => {
    const { field, config, fieldErrorMessages, fieldModifiedMessages, state, isDisabled, onChange } = this.props;
    const { selectedServiceType, filter } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_service_scope`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);

    const serviceTargets = field.targets || [];
    const opex_coa: any = field.opex || [];
    const capex_coa: any = field.capex || [];

    const columns = [
      {
        title: _.capitalize(selectedServiceType.replace('_', ' ')),
        render: (entity: any) => {
          return (
            <span>{ entity.target_title }</span>
          );
        }
      },
    ];

    let dataSource = this.dataMapping(selectedServiceType === 'opex_coa' ? opex_coa : capex_coa);

    if (filter) {
      dataSource = this.filterTable(dataSource, filter);
    }

    const flatList = this.getFlatten(dataSource);
    const selectedRowKeys = state
      .filter((service: any) => flatList.find((_service: any) => {
        return `${_service.target_type}-${_service.target_id}` === `${service.target_type}-${service.target_id}`;
      }))
      .map((service: any) => {
        return `${service.target_type}-${service.target_id}`;
      });

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        required={ field.config.required }
        versionChanged={ !!field.config.version_changed }
        border={ false }
      >
        <div className="d-f fxd-c w-100p" onChange={ () => null }>
          <div className="d-f jc-sb mB-10">
            <div className="d-f ">
              <Select
                defaultValue={ selectedServiceType || undefined }
                placeholder="Expenditure"
                className="mR-10"
                style={{ width: 200 }}
                onChange={ (value: string) => this.setState({ selectedServiceType: value }) }>
                  { serviceTargets.map((target) => <Option key={target.type} value={target.type}>{target.label}</Option>)}
              </Select>
              <Search
                disabled={ _.isEmpty(dataSource) && !filter }
                placeholder="Filter Services"
                style={{ width: 300 }}
                onSearch={(value: string) => {
                  this.setState({
                    filter: value
                  });
                }}
              />
            </div>

            { _.isEmpty(errors) && isModified && (
              <Tooltip
                overlayClassName="text-white"
                placement="topRight"
                title={ 'The field has been modified' }
              >
                <InfoIcon className="mR-10 text-warning" height={ 20 } width={ 20 } />
              </Tooltip>
            ) }
            { !_.isEmpty(errors) && (
              <Tooltip
                overlayClassName="text-white"
                placement="topRight"
                title={ errors.join(', ') }
              >
                <WarningIcon className="mR-10 text-danger" height={ 20 } width={ 20 } />
              </Tooltip>
            ) }
          </div>
          <Table
            size={ 'small' }
            className="Layout-box w-100p"
            columns={ columns }
            dataSource={ dataSource }
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              checkStrictly: false,
              renderCell: (checked: boolean, record: any, index: number, originNode: any) => {
                if (isDisabled) {
                  return React.cloneElement(originNode, { disabled: true });
                } else {
                  return originNode;
                }
              },
              onChange: (__: any, selectedRows: any) => {
                const flatList = this.getFlatten(dataSource);
                const preservedValues = state.filter((service: any) => !flatList.find((_service: any) => `${_service.target_type}-${_service.target_id}` === `${service.target_type}-${service.target_id}`));
                const newValues = selectedRows.map((row: any) => {
                  return {
                    'target_id': row.target_id,
                    'target_type': row.target_type,
                    'target_bundle': row.target_bundle,
                    'target_title': row.target_title,
                    'target_path': row.target_path,
                    'target_reference': row.target_reference,
                  };
                });
                onChange(field, concat(preservedValues, newValues), config);
              }
            }}
            pagination={ false }
          />
        </div>
      </FieldWrapper>
    );
  };
};

export default ServiceScope;