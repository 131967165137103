// Libs
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import {
  Modal,
  Menu,
  Dropdown as AntDropdown,
  Result,
} from 'antd';
import Jumbotron from "components/jumbotron";
import BasicListView from 'components/basic-list';
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionHoC } from 'components/restriction';
import Badge, { getBadgeType } from 'components/badge';
import { hasPermission } from 'components/restriction';
import Dropdown from 'components/dropdown';

// Views
import CreateUserDialog from 'views/admin/users/CreateUserDialog';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserStatus } from 'types/entities';
import { UserPermissions } from 'types/permissions';

interface Props {
  client_id: number;
  intl: IntlShape;
  user: UserEntity;
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  users: UserEntity[];
  activeUserId: number | null;
  isLoading: boolean;
  isSendingInvite: boolean;
  showCreateDialog: boolean;
  showReactivateDialog: boolean;
  showDeleteDialog: boolean;
  showResetDialog: boolean;
};

const API: Api = new Api();

class Users extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    users: [],
    activeUserId: null,
    isLoading: false,
    isSendingInvite: false,
    showCreateDialog: false,
    showReactivateDialog: false,
    showDeleteDialog: false,
    showResetDialog: false,
  };

  componentDidMount = () => {
    const { user: { active_client }, setBreadcrumbs } = this.props;

    this.mounted = true;

    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Admin', path: '/admin' },
      { title: 'Users', path: '/admin/users' },
    ], false);

    this.mounted && this.setState(
      {
        isLoading: true,
      }, () => {
        API.getUsers(active_client)
          .then((users: any) => {
            this.mounted && this.setState({
              users: users
            });
          })
          .catch((err: any) => {
            console.error(err);
          })
          .finally(() => this.mounted && this.setState({
            isLoading: false
          }));
      }
    );
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleUserInvite = async (userID: number) => {
    try {
      const { client_id } = this.props;

      await new Promise((resolve) => this.setState({ isSendingInvite: true }, () => resolve(null)));

      await API.sendUserInviteEmail(client_id, userID);

      Notification('success', 'User invite sent', 'Successful');
    } catch (e) {
      Notification('error', 'Failed to send invite user', 'Failed');
    } finally {
      this.mounted && this.setState({ isSendingInvite: false });
    }
  };

  renderUsers = () => {
    const { client_id, permissions } = this.props;
    const { users } = this.state;

    const canMasquerade = hasPermission(permissions, 'masquerade');

    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        filterable: false,
        render: (name: any, record: any) => {
          return <Link to={ `/admin/users/${record.id}` } style={ { 'color': '#2196f3' } }>{ name }</Link>;
        },
        sorter: true,
        ellipsis: true,
      },
      {
        key: 'company',
        dataIndex: 'company',
        title: 'Company',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'Email',
        sorter: true,
        ellipsis: true,
        filterable: false,
      },
      {
        key: 'phone',
        dataIndex: 'phone',
        title: 'Phone',
        sorter: true,
        ellipsis: true,
        filterable: false,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status: string) => {
          return <Badge type={ getBadgeType(status || '') } text={ _.startCase(_.toLower(status)).split('_').join(' ') } />;
        },
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        render: (type: string) => {
          const formattedType = _.startCase(_.toLower(type)).split('_').join(' ');
          return <Badge type={ getBadgeType(formattedType || '') } text={ formattedType } />;
        },
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        fixed: 'right',
        title: '',
        render: (title: any, user: any) => {
          const isMe = user.id === this.props.user.id;
          return (
            <>
              <AntDropdown.Button overlay={
                <Menu>
                  { user.status === UserStatus.Active && canMasquerade &&
                    <Menu.Item
                      key="masquerade"
                      disabled={ isMe }
                      onClick={ async () => {
                        try {

                          await API.put(`/client/${client_id}/user/masquerade/start`, {
                            user_id: user.id
                          });
                          window.location.reload();

                        } catch (error) {
                          Notification('error', '', 'Failed to start masquerading');
                        }
                      } }
                    >
                      Masquerade
                    </Menu.Item>
                  }
                  { user?.status === UserStatus.Active &&
                    <Menu.Item
                      key="reset"
                      onClick={ () => this.setState({ showResetDialog: true, activeUserId: user.id }) }
                    >
                        Reset Password
                    </Menu.Item>
                  }
                  { user?.status === UserStatus.Pending &&
                    <Menu.Item
                      key="resend"
                      onClick={ () => this.handleUserInvite(user.id) }
                    >
                      Resend Invite
                    </Menu.Item>
                  }
                  { user?.status === UserStatus.Deactivated &&
                    <Menu.Item
                      key="reactivate"
                      onClick={ () => this.setState({ showReactivateDialog: true, activeUserId: user.id }) }
                    >
                      Reactivate
                    </Menu.Item>
                  }
                  { user?.status === UserStatus.Active &&
                    <Menu.Item
                      key="delete"
                      danger
                      disabled={ isMe }
                      onClick={ () => !isMe && this.setState({ showDeleteDialog: true, activeUserId: user.id }) }
                    >
                      Deactivate
                    </Menu.Item>
                  }
                </Menu>
              } trigger={ ['click'] } ></AntDropdown.Button>
            </>
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      },
    ];

    const items = users.map((user: any, index: number) => {
      return {
        'key': index,
        'id': user.id,
        'name': `${user.first_name} ${user.last_name}`,
        'company': _.has(user, 'company.title') ? user.company.title : '',
        'email': user.email,
        'phone': user.phone_object ? `${user.phone_object.dial_code} ${user.phone_object.phone_number}` : '',
        'status': user.status,
        'type': user.type,
      };
    }).sort((a, b) => {
      return a.name.localeCompare(b.name); // By default, sort alphabetically by name
    });

    return (
      <BasicListView
        rawData
        defaultFilters={ { status: [UserStatus.Active] } }
        columns={ columns }
        items={ items }
      />
    );
  };

  renderCreateDialog =  () => {
    const { client_id, user } = this.props;
    return (
      <CreateUserDialog
        clientId={ client_id }
        userId={ user.id }
        onClose={ () => this.mounted && this.setState({ showCreateDialog: false }) }
      />
    );
  };

  renderReactivateDialog = (user_id: any) => {
    const { user: { active_client } } = this.props;
    const { users } = this.state;
    const currentUser: any = users.find((user: UserEntity) => user.id === user_id);
    return (
      <Modal
        visible
        title={ 'Reactivate User' }
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {
          try {

            const users = await API.put(`client/${active_client}/admin/users/${user_id}/reactivate`);

            this.mounted && this.setState({
              users: users
            });

            Notification('success', `Successfully reactivated user`);
          } catch (error) {
            Notification('error', `Failed to reactivate user`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              activeUserId: null,
              isLoading: false,
            });
          }
        } ) }
        onCancel={ () => this.setState({ showReactivateDialog: false, activeUserId: null }) }
      >
        <p>Are you sure you want to reactivate <b>{ currentUser.full_name }</b>?</p>
      </Modal>
    );
  };

  renderDeleteDialog = (user_id: any) => {
    const { user: { active_client } } = this.props;
    const { users } = this.state;
    const currentUser: any = users.find((user: UserEntity) => user.id === user_id);
    return (
      <Modal
        visible
        title={ 'Deactivate User' }
        okButtonProps={ {
          danger: true,
        } }
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {
          try {

            const users = await API.delete(`client/${active_client}/admin/users/${user_id}`);

            this.mounted && this.setState({
              users: users
            });

            Notification('success', `Successfully deactivated user`);
          } catch (error) {
            Notification('error', `Failed to deactivate user`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              activeUserId: null,
              isLoading: false,
            });
          }
        }) }
        onCancel={ () => this.setState({ showDeleteDialog: false, activeUserId: null }) }
      >
        <p>Are you sure you want to deactivate <b>{ currentUser.full_name }</b>?</p>
      </Modal>
    );
  };

  renderResetDialog = (user_id: any) => {
    const { user: { active_client } } = this.props;
    const { users } = this.state;
    const currentUser: any = users.find((user: UserEntity) => user.id === user_id);
    return (
      <Modal
        visible
        title={ 'Reset Password' }
        onOk={ () => this.mounted && this.setState({
          showResetDialog: false,
        }, () => {
          API.requestPasswordToken(currentUser.email, active_client)
            .then(() => {
              Notification('success', 'A reset link has been sent.', 'Successful');
            })
            .catch((err) => {
              Notification('error', 'Failed so send reset password link.', 'Failed');
            });
        }
        ) }
        onCancel={ () => this.setState({ showResetDialog: false, activeUserId: null }) }
      >
        <p>Are you sure you want to send a reset link to <b>{ currentUser.email }</b>?</p>
      </Modal>
    );
  };

  render = () => {
    const {
      isLoading,
      activeUserId,
      showCreateDialog,
      showReactivateDialog,
      showDeleteDialog,
      showResetDialog,
    } = this.state;

    if (process.env.REACT_APP_ENVIRONMENT === 'training') {
      return (
        <div className="d-f jc-c ai-c mT-20 mX-30 h-100p">
          <Result title="Disabled For Training" />
        </div>
      );
    }

    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ 'Users' }
          tabs={ [
            {
              label: '',
              node: this.renderUsers(),
            }
          ] }
          rightActions={ [
            {
              node: (
                <Dropdown
                  actions={ [
                    {
                      node: 'Create User Request',
                      onClick: () => this.setState({ showCreateDialog: true })
                    }
                  ] }
                />
              )
            }
          ] }
        />
        { showCreateDialog && this.renderCreateDialog() }
        { showReactivateDialog && !!activeUserId && this.renderReactivateDialog(activeUserId) }
        { showDeleteDialog && !!activeUserId && this.renderDeleteDialog(activeUserId) }
        { showResetDialog && !!activeUserId && this.renderResetDialog(activeUserId) }
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(injectIntl(Users), 'access_admin_users'));
