// Libs
import * as React from 'react';
import { Progress, Tooltip } from 'antd';

// Styles
import './Progress.scss';

export enum ProgressType {
  Line = 'line',
  Circle = 'circle',
};

interface Props {
  total: number,
  value: number,
  type?: ProgressType,
};

export const calculatePercentage = (total: number, value: number): number => {
  // Can't exceed total
  if (value > total) {
    return 100;
  }

  // Calculate and round to nearest whole number
  return Math.round((value / total) * 100);
};

export default function ProgressComponent({ total, value, type = ProgressType.Line }: Props) {
  return (
    <Tooltip placement={ 'top' } title={ `Completed ${value} out of ${total}` }>
      <Progress className="Progress" type={ type } percent={ calculatePercentage(total, value) } size="small" />
    </Tooltip>
  );
};
