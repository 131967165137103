// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';

// Services
import { Api } from 'services/api';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import UserRequests from 'views/UserRequests';
import UserRequestRecord from 'views/user-request/UserRequestRecord';
import UserRequestResources from 'views/user-request/UserRequestResources';
import UserRequestAudit from 'views/user-request/UserRequestAudit';
import UserRequestWorkflow from 'views/user-request/UserRequestWorkflow';
import UserRequestComments from 'views/user-request/UserRequestComments';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { RecordFormEntity } from 'types/entities';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setBreadcrumbsLoading,
} from 'store/UI/ActionCreators';

// Utils
import Console from 'utils/console';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  record: RecordFormEntity | null;
  isFetching: boolean;
};

const API: Api = new Api();

class UserRequestContainer extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    record: null
  };

  componentDidMount = () => {
    this.mounted = true;
    this.getRecord();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.record_id !== this.props.match.params.record_id) {
      this.getRecord();
    }
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  getRecord = async () => {
    try {
      const { match, client_id } = this.props;

      // Set default breadcrumbs
      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'User Requests', path: '/user-requests' },
      ], false);

      // Set default routes
      this.props.setSecondarySidebarRoutes([]);

      if (client_id && match.params.record_id) {

        await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));

        this.props.setBreadcrumbsLoading(true);

        const record = await API.get(`client/${client_id}/record/user-request/${match.params.record_id}`);

        this.setRecord(record);
      }
    } catch (error) {
      Console.error(error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.setState({
        isFetching: false
      });
    }
  };

  setRecord = (record: RecordFormEntity) => {
    const showLoadingState = this.state.record?.title !== record.title;

    if (showLoadingState) {
      this.props.setBreadcrumbsLoading(true);
    }

    if (_.has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    }

    if (_.has(record, 'permissions')) {
      const { permissions, new_content } = record;

      this.props.setSecondarySidebarRoutes([
        { title: 'User Request', path: 'record', isDisabled: !hasPermission(permissions, 'record_user_request_view') },
        { title: 'Comments', path: 'comments', isIndented: true, isDisabled: !hasPermission(permissions, 'record_user_request_comment_view'), hasUpdates: !!new_content.comments },
        { title: 'Resources', path: 'resources', isIndented: true, isDisabled: !hasPermission(permissions, 'record_user_request_resource_view'), hasUpdates: !!new_content.resources },
        { title: 'Workflow', path: 'workflow', isIndented: true, isDisabled: !hasPermission(permissions, 'record_user_request_workflow_view') },
        { title: 'Audit', path: 'audit', isIndented: true, isDisabled: !hasPermission(permissions, 'record_user_request_audit_view') },
      ]);
    }

    this.setState({
      record: record,
      isFetching: false
    });

    if (showLoadingState) {
      this.props.setBreadcrumbsLoading(false);
    }
  };

  render = () => {
    const { isFetching, record } = this.state;
    const permissions = record && _.has(record, 'permissions') ? record.permissions : this.props.permissions;
    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Switch>

          <Redirect exact from="/user-requests/:record_id" to="/user-requests/:record_id/record" />

          <Route exact path="/user-requests" render={ () => {
            return (
              <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_user_request_view_list') }>
                <UserRequests />
              </RestrictionWrapper>
            );
          } } />

          { record &&
            <Route exact path="/user-requests/:record_id/record" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_user_request_view') }>
                  <UserRequestRecord record={ record } setRecord={ this.setRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/user-requests/:record_id/resources" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(permissions, 'record_user_request_view') }>
                  <UserRequestResources record={ record } getRecord={ this.getRecord } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/user-requests/:record_id/audit" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(record.permissions, 'record_user_request_audit_view') }>
                  <UserRequestAudit record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/user-requests/:record_id/workflow" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(record.permissions, 'record_user_request_workflow_view') }>
                  <UserRequestWorkflow record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          { record &&
            <Route exact path="/user-requests/:record_id/comments" render={ () => {
              return (
                <RestrictionWrapper restricted={ !hasPermission(record.permissions, 'record_user_request_comment_view') }>
                  <UserRequestComments record={ record } />
                </RestrictionWrapper>
              );
            } } />
          }

          <Route render={ () => <NotFound /> } />

        </Switch>
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRequestContainer);
